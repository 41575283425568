.ham5 .top {
  stroke-dasharray: 40 82;
}
.ham5 .bottom {
  stroke-dasharray: 40 82;
}
.ham5.active1 .top {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}
.ham5.active1 .bottom {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active1 {
  transform: rotate(45deg);
}
.hamRotate180.active1 {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #072462;
  stroke-width: 5.5;
  stroke-linecap: round;
}
