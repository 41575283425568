.mobile-sidebar-container {
  transition: all 0.8s;
  position: fixed;
  z-index: 1001;
  width: 70%;
  max-width: 280px;
  height: 100vh;
  display: flex;
  left: 0;
}

.side-close {
  left: -70vw !important;
}

.mobile-sidebar {
  position: relative;
  height: 100%;
  width: 100%;
  color: white;
  background-color: #001d57;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobile-close-btn {
  transition: all 0.8s;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 30px;
  height: 30px;
}

.mobile-sidebar::-webkit-scrollbar {
  display: none;
}
