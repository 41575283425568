:root {
  --bs-primary: #00155c !important;
  --bs-secondary: #0027a8 !important;
  --bs-success: #2ecc71 !important;
  --bs-info: #3498db !important;
  --bs-warning: #f1c40f !important;
  --bs-danger: #e74c3c !important;
  --bs-light: #f8f9fa !important;
  --bs-dark: #212529 !important;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Noto Sans', 'Open Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}
*,
::after,
::before {
  box-sizing: border-box;
}

.btn {
  background-color: initial;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: none;
  color: #212529;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1em;
  padding: 0.375rem 0.75rem;
  padding: 10px 15px;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.btn-primary {
  background-color: #00124e;
  border-color: #00124e;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: #2450a8;
  border-color: #2450a8;
  color: #fff;
}
.btn-outline-primary:hover {
  background-color: #10456d;
  border-color: #10456d;
  color: #fff;
}
.btn-outline-primary {
  border-color: #10456d;
  color: #10456d;
}

.btn-light {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #000;
}

.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-light:hover {
  background-color: #f9fafb;
  border-color: #f9fafb;
  color: #000;
}

.btn-outline-warning {
  border-color: #e0614a;
  color: #e0614a;
}
.btn-outline-warning:hover {
  background-color: #e0614a;
  border-color: #e0614a;
  color: #000;
}

.btn-primary:hover {
  background-color: #0027a8;
  border-color: #0027a8;
  color: #fff;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #00155c;
  border-color: #00155c;
  color: #fff;
}

.wibondLink {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #001d57;
  color: white;
  border-radius: 5px;
  padding: 12px 75px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}
.wibondLink:hover {
  background: #0027a8;
}

.wibondLink:focus {
  outline: none;
}

.treatments_logo {
  position: absolute;
  top: 18px;
  right: -19px;
  width: 100px;
  border-radius: 25px 0px 25px 0px;
  transform: rotate(-90deg);
}

.type-1 {
  background-color: #001d57; /* Adulto Full */
}
.type-10 {
  background-color: #001d57; /* Adulto Full RC */
}
.type-2 {
  background-color: #2450a8; /* Adulto Fast */
}
.type-3 {
  background-color: #79dea8; /* Adulto Medium */
  color: #001d57 !important;
}
.type-4 {
  background-color: #ee5a24; /* Kids Full */
}
.type-13 {
  background-color: #ee5a24; /* Kids Full RC */
}
.type-9 {
  background-color: #ee5a24; /* Kids Medium */
}
.type-5 {
  background-color: #e8793a !important; /* Kids Fast */
}
.type-6 {
  color: #001d57 !important;
  background-color: rgb(214, 216, 0); /* Teens Full */
}
.type-15 {
  color: #001d57 !important;
  background-color: rgb(214, 216, 0); /* Teen Full RC */
}
.type-7 {
  color: #001d57 !important;
  background-color: rgb(252, 255, 88); /* Teens Fast */
}
.type-8 {
  color: #001d57 !important;
  background-color: rgb(253, 250, 169); /* Teens Medium */
}
.type-9 {
  background-color: #ee5a24; /* Teens Medium */
}
.type-10 {
  background-color: #001d57; /* Teens Medium */
}
.type-13 {
  background-color: #ee5a24; /* Teens Medium */
}
.type-15 {
  background-color: rgb(214, 216, 0); /* Teens Medium */
}

.clinic-history-button.type-1:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-1:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-1 {
  color: #001d57 !important;
}

.clinic-history-button.type-2:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-2:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}

.clinic-history-button.type-3:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-3:hover::after {
  content: 'Ver historia clinica';
  align-content: center;
  color: #001d57;
}

.clinic-history-button.type-4:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-4:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}

.clinic-history-button.type-5:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-5:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}

.clinic-history-button.type-6:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #000;
  font-weight: bold;
}
.clinic-history-button.type-6:hover::after {
  content: 'Ver historia clinica';
  align-content: center;
  color: #000;
}

.clinic-history-button.type-7:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #000;
  font-weight: bold;
}
.clinic-history-button.type-7:hover::after {
  content: 'Ver historia clinica';
  align-content: center;
  color: #000;
}

.clinic-history-button.type-8:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #000;
  font-weight: bold;
}
.clinic-history-button.type-8:hover::after {
  content: 'Ver historia clinica';
  align-content: center;
  color: #000;
}

.clinic-history-button.type-9:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-9:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}

.clinic-history-button.type-10:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-10:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-10 {
  color: #001d57 !important;
}

.clinic-history-button.type-13:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #fff;
  font-weight: bold;
}
.clinic-history-button.type-13:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}

.clinic-history-button.type-15:hover {
  width: 145px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #000;
  font-weight: bold;
}
.clinic-history-button.type-15:hover::after {
  content: 'Ver historia clinica';
  align-content: center;
  color: #000;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}

a {
  text-decoration: none !important;
}
.bg-primary {
  background-color: #00155c !important;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  color: #0027a8;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.px-6 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #001f86;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #001f86;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 39, 168, 0.25);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0027a8;
  border-color: #0027a8;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.text-primary {
  color: #00155c !important;
}
.text-secondary {
  color: #0027a8 !important;
}
.text-success {
  color: #2ecc71 !important;
}
.text-info {
  color: #3498db !important;
}
.text-warning {
  color: #f1c40f !important;
}
.text-danger {
  color: #e74c3c !important;
}
.text-light {
  color: #f8f9fa !important;
}
.text-dark {
  color: #212529 !important;
}
.text-white {
  color: #fff !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
  color: inherit !important;
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
#sidebar {
  width: 15%;
  height: 100%;
  float: left;
  color: #fff;
  background: #001d57;
  overflow: auto;
  transition: width 0.35s;
}
#sidebar .sidebar-row {
  margin-top: 30px;
}
#sidebar .sidebar-row .sidebar-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 0;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 300;
  display: inline-block;
  overflow: hidden;
}
#sidebar .sidebar-row .sidebar-item label {
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: left;
  font-weight: 300;
}
#sidebar.collapsed {
  width: 5%;
}
#sidebar.collapsed + #content {
  width: 95%;
}
#content {
  width: 85%;
  height: 100%;
  overflow: auto;
  transition: width 0.35s;
  background: #f8f9fa;
  padding-bottom: 30px;
  max-width: 100vw !important;
  padding: 0 0 30px 0;
}
#content-full {
  height: 100%;
  overflow: auto;
  transition: width 0.35s;
  background: #f8f9fa;
  padding-bottom: 30px;
}
.modal-content {
  border-radius: 30px;
}
.CH-image-container {
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
input:disabled {
  background: #ccc;
}
.viewerInput {
  padding: 10px;
  border: 1px solid #ccc;
  color: #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: 90%;
}

.viewerInput:disabled {
  background-color: #fff;
}

.copyBTN {
  width: 30%;
  text-align: center;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 15px;
  border: 1px solid #052051;
  color: #052051;
}

.copyBTN:hover {
  cursor: pointer;
  background-color: #052051;
  color: #fff;
  transition: all 0.3s;
}

.xBTN:hover {
  cursor: pointer;
  opacity: 0.7;
}
.shadow-search {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 3px 8px;
}
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}
.link-primary {
  color: #00155c;
}
.link-primary:focus,
.link-primary:hover {
  color: #00114a;
}
.link-secondary {
  color: #0027a8;
}
.link-secondary:focus,
.link-secondary:hover {
  color: #001f86;
}
.link-success {
  color: #2ecc71;
}
.link-success:focus,
.link-success:hover {
  color: #58d68d;
}
.link-info {
  color: #3498db;
}
.link-info:focus,
.link-info:hover {
  color: #5dade2;
}
.link-warning {
  color: #f1c40f;
}
.link-warning:focus,
.link-warning:hover {
  color: #f4d03f;
}
.link-danger {
  color: #e74c3c;
}
.link-danger:focus,
.link-danger:hover {
  color: #ec7063;
}
.link-light {
  color: #f8f9fa;
}
.link-light:focus,
.link-light:hover {
  color: #f9fafb;
}
.link-dark {
  color: #212529;
}
.link-dark:focus,
.link-dark:hover {
  color: #1a1e21;
}

.grow_hover:hover {
  transition: 0.15s ease-out;
  -webkit-transform: scale(1.005);
  -ms-transform: scale(1.005);
  transform: scale(1.005);
  background-color: rgb(243, 243, 243) !important;
  cursor: pointer;
}
.force-inline-text {
  white-space: nowrap !important;
}

.checkbox-container {
  margin: 10px 0;
  margin-top: 50px;
}
.img-dientes {
  width: 26px;
  margin-top: -60px;
}

input[type='checkbox'] {
  visibility: visible;
}

.checkboxOverride {
  position: relative;
  width: 25px;
  display: inline-block;
}

.checkboxOverride input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.checkboxOverride input[type='checkbox']:checked + label {
  color: white !important;
}

.checkboxOverride label {
  background: none repeat scroll 0 0 #eeeeee;
  border: 1px solid #dddddd;
  cursor: pointer;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
  padding-left: 0px;
  z-index: 100;
  margin-right: 5px;
  font-size: 12px;
  padding-top: 3px;
}

.checkboxOverride label:after {
  z-index: -1;
  content: '';
  height: 17px;
  left: 2.6px;
  opacity: 0;
  position: absolute;
  top: 3px;
  width: 17px;
  background: #00165c !important;
}

nav > .nav.nav-tabs {
  border: none;
  color: #000;
  background: #fff;
  border-radius: 0;
}
nav > div a.nav-item2.nav-link2 {
  border: none;
  padding: 18px 25px 15px 5px;
  color: #000;
  background: #fff;
  border-radius: 0;
  font-weight: bolder;
  text-align: left;
}

ul.stepper li a {
  padding: 0.5rem 1.5rem;
  text-align: center;
}

ul.stepper {
  padding: 0 1.5rem;
  margin: 0em -1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  counter-reset: section;
}

.stepper-vertical {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stepper-vertical li {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
}

.stepper-vertical li a {
  position: relative;
  display: flex;
  align-self: flex-start;
  width: 100%;
}

.circle-step {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  line-height: 1.7rem;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
  order: 2;
}

ul.stepper li.active a .label-step,
ul.stepper li.completed a .label-step {
  font-weight: 600;
}

.label-step {
  flex-flow: column nowrap;
  order: 1;
  color: gray;
  text-align: end;
  padding-right: 1rem;
}

.textStart {
  text-align: start;
}
.textEnd {
  text-align: end;
}
.form-online-header-border {
  border-bottom: 1px solid #d8d8d8;
}
.circle {
  width: 1rem;
  height: 1rem;
  line-height: 1.7rem;
  background-color: #9b9b9b;
  border-radius: 50%;
  z-index: 2;
}
.text-stepper {
  color: gray;
  font-size: 0.875em;
  font-weight: 600;
}
.text-stepper-normal {
  color: gray;
  font-size: 0.875em;
}
.vertical-line {
  font-weight: 600;
  width: 2px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}
.full-vertical-line {
  font-weight: 600;
  width: 2px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.line-horizontal-form-online {
  width: 40px;
  height: 5px;
  background-color: #000;
}
.ms-xl-L1 {
  margin-left: -2.8rem !important;
}
.clickeable {
  cursor: pointer;
}

.teethInput {
  visibility: hidden !important;
}
.disabled {
  opacity: 0.5;
}
.hover-bold {
  font-weight: lighter;
}

.hover-bold:hover {
  transition: 0.3s;
  font-weight: bold;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 39, 168, 0.05) !important;
}
.seeButton,
.seeBTN {
  background-color: #f5f5f5;
  border-radius: 50px;
  border: 1px solid #00155c;
  color: #00155c;
}

.seeButton:hover,
.seeBTN:hover {
  background-color: #00155c;
  border: 1px solid #f5f5f5;
  border-radius: 50px;
  color: #f5f5f5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.inActiveLink {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 15px;
  color: #00155c;
  background-color: #fff;
  border: 1px solid #00155c;
  margin-right: 10px;
  height: 26px;
  cursor: pointer;
}
.activeLink {
  display: flex;
  align-items: center;
  border-radius: 15px;
  background-color: #00155c !important;
  color: #fff !important;
  margin-right: 10px;
  padding: 5px 8px;
  font-size: 14px;
  border: 1px solid transparent;
  cursor: pointer;
}

.actionButtons {
  color: #001d57;
  background-color: transparent;
}

.actionButtonsContainer:hover {
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  background-color: #50555c1a;
  padding: 1px;
}
.object-cover {
  object-fit: cover;
}
.odContainerCard {
  max-width: 1247px;
  border-radius: 15px;
  height: 48px;
  margin: auto;
  color: #5c6270;
}
.closeModalButton {
  color: #ccc;
}
.modal-header {
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer;
}

.closeModalButton:hover {
  cursor: pointer;
  color: #aaa;
  transition: all 0.3s;
}
.inputError {
  outline: 1px solid #e74c3c !important;
  border: 1px solid transparent !important;
}

.labelError {
  color: #e74c3c;
}

.pe-6 {
  padding-right: 5rem !important;
}
.pb-6 {
  padding-bottom: 5rem !important;
}
.fs-7 {
  font-size: 0.82rem !important;
}

.NT-bottom-bar {
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(230, 230, 230);
}

.NT-bottom-bar-two {
  border-bottom: 1px solid rgb(161, 161, 161);
}
.dropdown-menu {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 39, 168, 0.35);
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 39, 168, 0.05);
  color: #212529;
  display: none;
  font-size: 1rem;
  left: 0;
  list-style: none;
  margin: 0.125rem 0 0;
  min-width: 10rem;
  padding: 0.5rem;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}

.tc-container {
  padding-top: 10px;
  position: relative;
}
.tc-p {
  font-size: 0.9em;
  margin-top: 5px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.tc-small {
  font-size: 0.7em;
}
/* .tc-container::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 7px;
  height: 90%;
  background-color: var(--bs-danger);
  border-radius: 10px;
} */
.XBtn:hover {
  cursor: pointer;
  color: #444;
}
.CH-image-container {
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.black {
  color: black;
}
.type-tag {
  color: aliceblue;
  text-align: center;
  line-height: 100%;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-gray {
  color: rgb(2, 2, 2, 0.3);
}
.CH-small {
  font-size: 14px;
}
.CH-stage-tag {
  border-radius: 10px;
  height: 25px;
  background-color: #dce2ff;
}
.CH-select {
  border: none;
  border-bottom: #001c41 1px solid;
}
.CH-blueKS {
  background-color: rgb(220, 226, 255);
}
.CH-aprobado {
  background-color: #87c28f;
  color: #0d841d;
}
.CH-pendiente {
  border: none;
  outline: none;
  background-color: #f8e1b2;
  color: #f0bb51;
}
.CH-rechazado {
  background-color: #f8beb2;
  color: #f07751;
}
.CH-comment-icon {
  cursor: pointer;
  margin: 0px 16px;
}
.CH-space-maker {
  border-bottom: 2px solid rgb(200, 200, 200);
  height: 40px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.CH-flags-btn-blue {
  outline: none;
  border: 1px solid #10456d;
  margin: 0px 5px;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  color: #10456d;
  background: white;
}
.disabled {
  opacity: 0.5;
}
.CH-flags-btn-red {
  outline: none;
  border: 1px solid #f53535;
  margin: 0px 5px;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
  background: #f53535;
}
.CH-flags-btn-red:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}
.CH-flags-btn-blue:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}
.CH-flags-btn-red:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}
.CH-flags-btn-blue:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
  outline: none;
}
.red {
  color: #f53535;
}
.green {
  color: rgb(70, 217, 70);
}
.blue {
  color: #10456d;
}
.modal-container {
  z-index: 1;
  border-radius: 20px;
}
.gallery {
  z-index: 200;
}

.avatar_image {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.dropdown {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0027a8;
}

.p-0 {
  padding: 0px !important;
}

.text-green {
  color: rgb(70, 217, 70);
}
.text-red {
  color: rgb(217, 70, 70);
}
.text-yellow {
  color: #fca223;
}

p {
  margin: 0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.show {
  display: block !important;
}
.mx-6 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.scanButton {
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
  color: #001b70;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.scanButton:hover {
  cursor: default !important;
}
.scanButtonText {
  margin: 0;
  border-radius: 15px;
  padding: 25px 0px;
  background-color: #001b70;
  color: #fff;
}
.btn-danger {
  background-color: #e74c3c;
  border-color: #e74c3c;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #000;
}
.btn-sm {
  border-radius: 5px;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}
.my-6 {
  margin-bottom: 5rem !important;
  margin-top: 5rem !important;
}
.step {
  background-color: #001b70;
  color: white;
  border: none;
  font-weight: bold;
  outline: none !important;
  cursor: default;
}

.uploadBTN {
  z-index: 110;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 3px;
}

.circulo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #000;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-90 {
  width: 90% !important;
}

.ps-patient {
  padding-left: 30px !important;
}
.back-orange {
  background-color: rgb(233, 156, 1) !important;
}
.z-1000 {
  z-index: 1000;
}
dialog {
  border: none;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 700px;
  margin: auto;
  margin-top: 200px;
  max-width: 100%;
  text-align: center;
}
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}
.dialog-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.dialog-body {
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  display: grid;
}
.close-btn,
.open-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #6200ea;
  color: white;
}
.close-btn {
  background-color: #d32f2f;
}
