.passwordTooltip {
  position: absolute;
  top: 32px;
  left: -30px;
  max-width: 22px;
}
.tooltip {
  text-align: center;
  line-height: 17px;
  max-width: 20%;
}
