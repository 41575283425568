.ksLogo {
  width: 100px;
  height: auto;
}

.titleStepTwo {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.mainContainerStepTwo {
  display: flex;
}

.textStepTwo {
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
  width: 86%;
}
.listTitleStepTwo {
  font-size: 20px;
  text-align: left;
  width: 86%;
}

.listItemStepTwo {
  display: flex;
  align-items: center;
}

.listItemStepTwo > div {
  background-color: #002e50;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  color: transparent;
  margin-right: 3px;
}

.listItemStepTwo > p {
  font-size: 20px;
  margin: 0;
}

.quoteStepTwo {
  width: 60%;
}

.videoSquareStepTwo {
  margin-top: 40px;
  border: 1px solid #000;
  width: 50%;
  color: transparent;
}

.stepTwoContainer {
  display: flex;
}

.footerStepTwo {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.footerStep3 {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.textContainerStepTwo {
  width: 50%;
}

.stepsFather {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 40px 10px;
}
.currentStep {
  background-color: #002e50;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin: 0px 7px;
  color: transparent;
}
.step {
  background-color: #b2abab;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin: 0px 7px;
  color: transparent;
}
.step:hover {
  cursor: pointer;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  text-align: center;
}

.text {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}

.watch {
  font-size: 28px;
}

.startButton {
  color: #002e50;
  font-size: 20px;
  font-weight: bold;
}

.closeModalButton {
  color: #ccc;
}
.closeModalButton:hover {
  cursor: pointer;
  color: #aaa;
  transition: all 0.3s;
}

.closeModalButton:focus {
  outline: none;
}

.buttonSectionContainer {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1000px) {
  .buttonSectionContainer {
    display: flex;
    justify-content: center;
    margin-block: 10px;
  }
  .titleStepTwo {
    text-align: center;
  }

  .mainContainerStepTwo {
    flex-direction: column-reverse;
    text-align: center;
  }

  .videoSquareStepTwo {
    height: 230px;
    width: 100%;
  }
  .textContainerStepTwo {
    width: 100%;
    margin-bottom: 20px;
  }

  .textContainerStepTwo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .listItemStepTwo {
    margin: 5px;
  }
  .listTitleStepTwo {
    text-align: center;
  }
  .textStepTwo {
    text-align: center;
  }
  .footerStepTwo {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
  }
  .quoteStepTwo {
    font-size: 14px;
    width: 70%;
    text-align: center;
  }
  .footerStep3 {
    display: flex;
    justify-content: center;
  }
}
