body,
#root {
  margin: 0;
  font-family: 'Google Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: relative;
}

* {
  margin: 0;
  font-family: 'Google Sans', 'Lato', sans-serif;
}
/* 

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00155c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00155c;
}

code {
  font-family: 'Google Sans', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

.custom-container {
  padding: 0px 24px;
  max-width: 1247px;
  margin: auto;
}

input.form-control,
select.form-control {
  border: none;
  border-bottom: 2px solid #0027a8;
  margin-bottom: 1rem;
  height: 35px;
  border-radius: 0 !important;
}

textarea.form-control {
  border-bottom: 2px solid #0027a8;
}

label {
  margin-bottom: 5px;
  font-weight: 500;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

textarea {
  max-width: 100%;
  max-height: 100%;
  border-bottom: 2px solid #0027a8;
  padding-left: 5px;
  padding-right: 5px;
}

textarea {
  resize: none !important;
}

#sidebar::-webkit-scrollbar {
  display: none !important;
}

#sidebar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.shadow {
  background-color: #fff;
}

.dropdown-toggle::after {
  content: none;
}

#instructiveModal {
  background-color: rgba(0, 0, 0, 0.3);
}

#modal-style {
  position: absolute !important;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -30%);
  border-radius: 20px !important;
}

#modal-style button.btn-modal {
  border-radius: 10px;
}

ol.carousel-indicators {
  position: absolute !important;
  bottom: -30px;
}

.carousel-indicators li {
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  margin: 5px !important;
}

.carousel-indicators li.active {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 10px !important;
}

.modal-close-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-top: 20px;
  padding-right: 30px;
}

.safari-modal {
  display: block !important;
  position: fixed !important;
  z-index: 9999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background-color: rgba(0, 0, 0, 0.3);
}

.safari-modal .container {
  background-color: white;
  border-radius: 20px !important;
  padding: 15px;
  margin: auto;
  max-width: 75vw;
  max-height: 90vh;
  width: 100%;
}

.safari-modal .bg-white {
  background-color: white;
  border-radius: 20px;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.safari-modal img {
  margin: auto;
}

.modal-close-container {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  #modal-style {
    width: 75vw;
    max-height: 90vh;
    margin: auto;
    margin-top: 60px;
  }

  #modal-style img,
  #modal-style picture {
    width: 100%;
    height: auto;
  }

  .safari-modal {
    display: block !important;
    position: fixed !important;
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .safari-modal .container {
    background-color: white;
    border-radius: 20px !important;
    padding: 15px;
    max-height: 95vh;
    margin: auto;
  }

  .safari-modal .bg-white {
    background-color: white;
    padding: 15px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }

  .safari-modal img {
    max-width: 60%;
    height: auto;
    margin: auto;
  }

  .modal-close-container {
    padding-top: 10px;
  }
}

.clinic-history-button {
  width: 20px;
  height: 100%;
  overflow: hidden;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  white-space: nowrap;
  border-radius: 15px 0 0 15px;
  position: absolute;
  border: none;
  cursor: pointer;
  color: #00155c;
  outline: none;
  z-index: 99;
  left: 0;
  top: 0;
}
.clinic-history-button:hover {
  width: 160px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

iframe {
  width: 100%;
  height: 600px;
}

/* SCICONS */
.sc-beginner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  background-color: lightblue;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-beginner::after {
  content: 'B';
  font-weight: bolder;
}

.sc-silver {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  background-color: darkgray;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-silver::after {
  content: 'S';
  font-weight: bolder;
}

.sc-gold {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  background-color: #ffd700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-gold::after {
  content: 'G';
  font-weight: bolder;
}

.sc-platinum {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-platinum::after {
  content: 'P';
  font-weight: bolder;
}

.sc-black {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-black::after {
  content: 'B';
  font-weight: bolder;
}
/* SCICONS */

ul.ul-primary {
  list-style: none; /* Remove default bullets */
}

ul.ul-primary li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00155c; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.search-input {
  padding-left: 15px;
  position: relative;
  padding-left: 12px 10px !important;
}

.search-input::before {
  content: '\f109';
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
}

.remove-scroll::-webkit-scrollbar {
  display: none;
}

.remove-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: darkred;
  display: inline-block;
  margin-left: 4px;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.color-full {
  color: #fff !important;
  background-color: #001d57 !important;
  border: 1px solid #001d57 !important;
}

.color-full.activeColor {
  color: #001d57 !important;
}

.color-full:is(.activeColor):hover {
  color: #fff !important;
  background-color: #1a3979 !important;
}

.color-kids {
  color: #fff !important;
  background-color: #ee5a24 !important;
  border: 1px solid #ee5a24 !important;
}

.color-kids.activeColor {
  color: #ee5a24 !important;
}

.color-kids:is(.activeColor):hover {
  color: #fff !important;
  background-color: #f17b50 !important;
}

.color-fast {
  color: #fff !important;
  background-color: #2450a8 !important;
  border: 1px solid #2450a8 !important;
}

.color-fast.activeColor {
  color: #2050a8 !important;
}

.color-fast:is(.activeColor):hover {
  color: #fff !important;
  background-color: #4a71b9 !important;
}

.color-kidsfast {
  color: #fff !important;
  background-color: #d48a5f !important;
  border: 1px solid #d48a5f !important;
}

.color-kidsfast.activeColor {
  color: #99430d !important;
}

.color-kidsfast:is(.activeColor):hover {
  color: #fff !important;
  background-color: #db966e !important;
}

.color-medium {
  color: #001d57 !important;
  background-color: #79dea8 !important;
  border: 1px solid #79dea8 !important;
}

.color-medium.activeColor {
  color: #001d57 !important;
}

.color-medium:is(.activeColor):hover {
  background-color: #94e5b9 !important;
}

.color-teens {
  color: #001d57 !important;
  background-color: #ffc107 !important;
  border: 1px solid #ffc107 !important;
}

.color-teens:is(.activeColor):hover {
  color: #001d57 !important;
  background-color: #fcd45b !important;
}

.activeColor {
  background-color: white !important;
}

.errorImportantClaims {
  color: #e74c3c !important;
}

.placeholder-center::-webkit-input-placeholder {
  padding-top: 10px;
  text-align: center;
}

.relative {
  position: relative;
}
.inputError {
  outline: 1px solid #e74c3c !important;
  border: 1px solid transparent !important;
}

.labelError {
  color: #e74c3c;
}

.color-adults {
  color: #fff !important;
  background-color: #001d57 !important;
  border: 1px solid #001d57 !important;
}

.color-adults.activeColor {
  color: #001d57 !important;
  background-color: #fff !important;
}

.color-adults:is(.activeColor):hover {
  color: #fff !important;
  background-color: #1a3979 !important;
}
.color-teen {
  color: #fff !important;
  background-color: #d6d800 !important;
  border: 1px solid #d6d800 !important;
}

.color-teen.activeColor {
  color: #d6d800 !important;
  background-color: #fff !important;
}

.color-teen:is(.activeColor):hover {
  color: #fff !important;
  background-color: #dde100 !important;
}
.color-kids {
  color: #fff !important;
  background-color: #ee5a24 !important;
  border: 1px solid #ee5a24 !important;
}

.color-kids.activeColor {
  color: #ee5a24 !important;
  background-color: #fff !important;
}

.color-kids:is(.activeColor):hover {
  color: #fff !important;
  background-color: #ff6128 !important;
}
.color-typeTreatment {
  color: #000 !important;
  background-color: #bebebe !important;
  border: 1px solid #aeaeae !important;
}

.color-typeTreatment.activeColor {
  color: #000 !important;
  background-color: #fff !important;
}

.color-typeTreatment:is(.activeColor):hover {
  color: #000 !important;
  background-color: #e0e0e0 !important;
}
