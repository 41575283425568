.drop-down {
  background-color: #fff;
  border: 1px solid #072462;
  border-radius: 5px;
  padding: 10px 0;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 11;
}
.dropdown-item {
  background-color: initial;
  border: 0;
  clear: both;
  color: #212529;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  padding: 0.25rem 1rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  margin-block: 3px;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
  color: #1e2125;
}
.sidebar-flag {
  width: 30px;
  height: 20px;
  margin-left: 30px;
}

.nav-arrow-down {
  transition: all 0.4s;
  margin-left: 8px;
}
.nav-up {
  rotate: 180deg;
}

.nav-right {
  line-height: 100%;
}

.navbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.nav-bell {
  margin-right: 24px;
}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }

  .nav-bell {
    margin-right: 2px;
    padding-top: 2px;
  }

  .drop-down {
    left: -95px;
  }
}

.enviromentText {
  text-align: center;
  flex: 1;
  padding: 10px;
  background: #e8793a;
  background: #000;
  color: #fff;
  border-radius: 15px 0px 15px 0px;
  user-select: none;
  font-weight: bold;
}
