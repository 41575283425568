.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  text-align: center;
}

.text {
  padding: 40px 30px 10px 30px;
  font-size: 20px;
  text-align: center;
}

.icon {
  font-size: 28px;
  margin-bottom: 50px;
}

.button {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #001d57;
  color: #fff;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.button:hover {
  color: #fff;
  background: #0027a8;
}
