/* Default-small */
.default-small {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #001d57;
  color: white;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.default-small:hover {
  background: #0027a8;
}

.default-small:focus {
  outline: none;
}

.default-small:disabled {
  background-color: #c9d6f8;
  cursor: auto;
}

/* Default-medium */

.default-medium {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #001d57;
  color: white;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.default-medium:hover {
  background: #0027a8;
}

.default-medium:focus {
  outline: none;
}

.default-medium:disabled {
  background-color: #c9d6f8;
  cursor: auto;
}

/* Default large */
.default-large {
  width: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #001d57;
  color: white;
  border-radius: 5px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.default-large:hover {
  background: #0027a8;
}

.default-large:focus {
  outline: none;
}

.default-large:disabled {
  background-color: #c9d6f8;
  cursor: auto;
}

/* Danger-small */

.danger-small {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #df2828;
  color: white;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.danger-small:hover {
  background: #a91919;
}

.danger-small:focus {
  background: #6b1010;
  outline: none;
}

.danger-small:disabled {
  background-color: #efbdbd;
  cursor: auto;
}

/* Danger-medium */

.danger-medium {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #df2828;
  color: white;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.danger-medium:hover {
  background: #a91919;
}

.danger-medium:focus {
  background: #6b1010;
  outline: none;
}

.danger-medium:disabled {
  background-color: #efbdbd;
  cursor: auto;
}

/* white-small */

.white-small {
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  background: white;
  color: #001d57;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.white-small:hover {
  background: #f6f6f6;
}

.white-small:focus {
  background: #f2f2f2;
  outline: none;
}

.white-small:disabled {
  box-shadow: none;
  color: transparent;
  background: transparent;
  cursor: auto;
}

/* white-medium */

.white-medium {
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  background: white;
  color: #001d57;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.white-medium:hover {
  background: #f6f6f6;
}

.white-medium:focus {
  background: #f2f2f2;
  outline: none;
}

.white-medium:disabled {
  box-shadow: none;
  color: transparent;
  background: transparent;
  cursor: auto;
}

/* danger-line-small */

.danger-line-small {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: transparent;
  color: #df2828;
  outline: 2px solid #df2828;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;
  border: none;
  font-weight: bold;
}
.danger-line-small:focus {
  outline: 2px solid #df2828;
}

.danger-line-small:disabled {
  color: #efbdbd;
  outline: 2px solid #efbdbd;
  cursor: auto;
}

/* danger-line-medium */

.danger-line-medium {
  font-weight: bold;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: transparent;
  color: #df2828;
  outline: 2px solid #df2828;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 100%;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;
  border: none;
}
.danger-line-medium:focus {
  outline: 2px solid #df2828;
}

.danger-line-medium:disabled {
  color: #efbdbd;
  outline: 2px solid #efbdbd;
  cursor: auto;
}

/* blue-line-small */

.blue-line-small {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: transparent;
  color: #001d57;
  outline: 2px solid #001d57;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;
  border: none;
  font-weight: bold;
}
.blue-line-small:focus {
  outline: 2px solid #001d57;
}

.blue-line-small:disabled {
  color: #c9d6f8;
  outline: 2px solid #c9d6f8;
  cursor: auto;
}

/* blue-line-medium */

.blue-line-medium {
  font-weight: bold;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: transparent;
  color: #001d57;
  outline: 2px solid #001d57;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 100%;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;
  border: none;
}
.blue-line-medium:focus {
  outline: 2px solid #001d57;
}

.blue-line-medium:disabled {
  color: #c9d6f8;
  outline: 2px solid #c9d6f8;
  cursor: auto;
}

/* ghost-small */
.ghost-small {
  font-weight: bold;
  background: transparent;
  color: #001d57;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
}

.ghost-small:hover {
  background: #ecf1ff;
}

.ghost-small:focus {
  outline: none;
  background: #c9d6f8;
}

.ghost-small:disabled {
  background-color: transparent;
  cursor: auto;
  color: #c9d6f8;
}

/* ghost-medium */

.ghost-medium {
  font-weight: bold;
  background: transparent;
  color: #001d57;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
}

.ghost-medium:hover {
  background: #ecf1ff;
}

.ghost-medium:focus {
  background: #c9d6f8;
  outline: none;
}

.ghost-medium:disabled {
  background-color: transparent;
  color: #c9d6f8;
  cursor: auto;
}

.green-fixed {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: white;
  border-radius: 5px;
  padding: 9px 16px;
  line-height: 100%;
  font-size: 14px;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: bold;
  background-color: #33b558;
  color: rgb(255, 255, 255);
  /* position: fixed; */
  /* bottom: 150px; */
  /* right: 350px; */
}
.green-fixed:disabled {
  background-color: #64ce82c6;
  cursor: auto;
}
.backButton,
.backButtonMobile {
  padding: 5px;
}

.backButton {
  margin-left: -50px;
}

.backButton:hover {
  color: #fff;
  border-radius: 100%;
  background-color: #001d57;
  cursor: pointer;
  transform: scale(1.2);
  opacity: 80%;
  transition: all 0.5s;
}
