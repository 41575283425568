.sb-top-logo {
  padding: '5px';
  margin: auto;
  margin-top: 10px;
  max-width: 140px;
  margin-bottom: 50px;
}

.side-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scale {
  transition: 0.1s;
  color: white;
}
.scale:hover {
  scale: 1.025;
  color: white;
}

.sb-icons {
  width: 16px;
  height: 16px;
}

.sb-item-padding {
  padding: 8px 16px;
}

.sb-drop {
  transition: all 0.6s;
  padding-left: 40px;
  padding-bottom: 24px;
}

.sb-active {
  display: flex;
  flex-direction: column;
  opacity: 1;
  margin-top: -20px;
}
.sb-inactive {
  position: absolute;
  transform: translateY(-160px);
  opacity: 0;
  z-index: -100;
}
.sb-inactive::after {
  display: none;
}

.tr-500 {
  transition: 0.3s;
}

.rotate-180 {
  rotate: 180deg;
}

.sb-inactive-text {
  position: absolute;
  top: 25px;
  left: 40px;
  font-size: 12px;
}
.sb-inactive-text-subitem {
  position: absolute;
  top: 34px;
  font-size: 12px;
}
.sidebarItem:is(.disabled):hover {
  opacity: 0.87 !important;
}

@media screen and (max-width: 1200px) {
  .sb-item-padding {
    padding: 8px 0px;
  }
}
