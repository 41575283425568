.DD-notification-card {
  border-bottom: 1px solid rgb(199, 199, 199);
  padding: 20px 0px;
  display: flex;
  align-items: center;
}
.DD-avatar {
  width: 40px;
  height: 40px;
  margin: 0px 10px;
  border-radius: 400px;
  border: 1px solid gray;
}
.DD-circle-yellow {
  width: 16px;
  height: 16px;
  border-radius: 400px;
  background: rgb(249, 249, 1);
}
.DD-circle-green {
  width: 16px;
  height: 16px;
  border-radius: 400px;
  background: rgb(68, 201, 70);
}

.DD-user-name {
  max-width: 160px;
}

.DD-notifications-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 420px;
  min-width: 380px;
  position: absolute;
  right: -1rem;
  top: 65px;
  z-index: 99999999999;
}

.DD-notifications-container::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #fff;
  position: absolute;
  top: -10px;
  right: 32px;
  transform: rotate(45deg);
}
.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: 5px !important;
}
.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: 5px !important;
}

.DD-red-tag {
  top: 7px;
  left: 29px;
  background-color: rgb(255, 80, 80);
  position: absolute;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.DD-red-tag-container:hover {
  scale: 1.15;
}
.DD-red-tag-container {
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  .DD-notifications-container {
    min-width: 400px;
    right: -3.5rem;
  }
  .DD-notifications-container::after {
    right: 4.5rem;
  }
  .DD-user-name {
    max-width: 200px;
  }
}
@media screen and (max-width: 600px) {
  .DD-notifications-container {
    min-width: 295px;
    right: -4rem;
  }
  .DD-notifications-container::after {
    right: 5rem;
  }
  .DD-user-name {
    max-width: 130px;
  }
}
@media screen and (max-width: 300px) {
  .DD-notifications-container {
    min-width: 260px;
    scale: 0.9;
    right: -4rem;
    top: 2.5rem;
  }
  .DD-notifications-container::after {
    right: 4.8rem;
  }
  .DD-user-name {
    max-width: 100px;
  }
}
